import { motion } from "framer-motion";
import { MainState } from "../../MainProvider";
import Logo from "../../images/footer/logo.svg";
import "./styles.footer.scss";
import { useNavigate } from "react-router-dom";
// Phần footer của trang web
export default function CFooter() {

    const { widthWeb } = MainState();
    const navigate = useNavigate();

    return (
        <>
            {widthWeb > 1100 &&
                <motion.div className='footer-main'
                >
                    <div className="logo-footer-main">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="info-footer-main">
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                8am
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Trang tổng hợp tin tức bằng AI từ nhiều nguồn nhất
                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Liên hệ
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Điện thoại: 024-3736-6491
                                </div>
                                <div className="info-content-item">
                                    Email: xinchao@8am.vn
                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Theo dõi 8am trên
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Facebook
                                </div>
                                <div className="info-content-item">
                                    Instagram
                                </div>
                                <div className="info-content-item">
                                    Linkedin
                                </div>
                                <div className="info-content-item">
                                    Youtube
                                </div>
                                <div className="info-content-item">
                                    Spotify
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="policy-security-footer-main">
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                8am.vn © 2023
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Thiết kế và phát triển: Viện Inres.AI
                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title"
                                onClick={() => navigate('/termsofuse')}
                                style={{ cursor: 'pointer' }}
                            >
                                Chính Sách Bảo Mật
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Thỏa Thuận Người Dùng
                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Cookies
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    RSS
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
            {(widthWeb <= 1100 && widthWeb > 450) &&
                <motion.div className='footer-main'
                >
                    <div className="logo-footer-main">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="info-footer-main">
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                8am
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Trang tổng hợp tin tức bằng AI từ nhiều nguồn nhất
                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Liên hệ
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Điện thoại: 024-3736-6491
                                </div>
                                <div className="info-content-item">
                                    Email: xinchao@8am.vn
                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Theo dõi 8am trên
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Facebook
                                </div>
                                <div className="info-content-item">
                                    Instagram
                                </div>
                                <div className="info-content-item">
                                    Linkedin
                                </div>
                                <div className="info-content-item">
                                    Youtube
                                </div>
                                <div className="info-content-item">
                                    Spotify
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="policy-security-footer-main">
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                8am.vn © 2023
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Thiết kế và phát triển: Viện Inres.AI
                                </div>
                                <div className="info-content-item">
                                    Số giấy phép: 548/GP-BTTTT ngày 24/08/2021                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Chính Sách Bảo Mật
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Thỏa Thuận Người Dùng
                                </div>

                            </div>
                        </div>
                        {/* <div className="info-footer-main-item">
                            <div className="info-title">
                                Cookies
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    RSS
                                </div>
                            </div>
                        </div> */}
                    </div>
                </motion.div>

            }
            {(widthWeb <= 450) &&
                <motion.div className='footer-main-responsive'
                >
                    <div className="logo-footer-main">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="info-footer-main">
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                8am
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Trang tổng hợp tin tức bằng AI từ nhiều nguồn nhất
                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Liên hệ
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Điện thoại: 024-3736-6491
                                </div>
                                <div className="info-content-item a">
                                    Email: <span> xinchao@8am.vn</span>
                                </div>
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Theo dõi 8am trên
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Facebook
                                </div>
                                <div className="info-content-item">
                                    Instagram
                                </div>
                                <div className="info-content-item">
                                    Linkedin
                                </div>
                                <div className="info-content-item">
                                    Youtube
                                </div>
                                <div className="info-content-item">
                                    Spotify
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="policy-security-footer-main">
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                8am.vn © 2023
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    Thiết kế và phát triển: Viện Inres.AI
                                </div>
                                <div className="info-content-item">
                                    Số giấy phép: 548/GP-BTTTT ngày 24/08/2021
                                </div>
                            </div>
                        </div>

                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Chính Sách Bảo Mật
                            </div>
                            <div className="info-content">
                                Thỏa Thuận Người Dùng
                            </div>
                        </div>
                        <div className="info-footer-main-item">
                            <div className="info-title">
                                Cookies
                            </div>
                            <div className="info-content">
                                <div className="info-content-item">
                                    RSS
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </>

    );
}