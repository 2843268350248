/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { API_URL } from '../../common/enum/enum'

export const getClusterBySize = (req: any) => {
    return axios.get(`${API_URL.HOST}/clusters?size=${req.size}&offset=${req.offset}`);
}

export const getClusterByCategoryId = (req: any) => {
    return axios.get(`${API_URL.HOST}/clusters?categoryId=${req.categoryId}&size=${req.size}&offset=${req.offset}`);
}

export const getTopFiveMostNewsInCategory = () => {
    return axios.get(`${API_URL.HOST}/clusters/top5`);
}