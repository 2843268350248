import axios from 'axios';
import { API_URL } from '../../common/enum/enum';

export const register = (req: any) => {
    return axios.post(`${API_URL.HOST}/users/register`, req);
}

export const login = (req: any) => {
    return axios.post(`${API_URL.HOST}/users/login`, req);
}

export const loginGoogle = (req: any) => {
    return axios.post(`${API_URL.HOST}/users/login-google`, req);
}

export const loginFacebook = (req: any) => {
    return axios.post(`${API_URL.HOST}/users/login-facebook`, req);
}

export const getUser = (req: any) => {
    return axios.get(`${API_URL.HOST}/users/user`, {
        headers: {
            Authorization: `Bearer ${req.token}`
        }
    });
}

export const getProfile = (req: any) => {
    return axios.get(`${API_URL.HOST}/users/profile`, {
        headers: {
            Authorization: `Bearer ${req.token}`
        }
    });
}

export const refreshToken = (req: any) => {
    return axios.post(`${API_URL.HOST}/users/refreshtoken`, req);
}

export const forgotPassword = (req: any) => {
    return axios.post(`${API_URL.HOST}/users/forgot-password`, req);
}

export const resetPassword = (req: any) => {
    return axios.post(`${API_URL.HOST}/users/reset-password`, req);
}   