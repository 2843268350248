import './App.css';
import './App.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import CMainRouter from './components/CMainRouter';
import { Layout, notification } from 'antd';
import AnimationRouter from './components/AnimationRouter';
import CFooter from './components/Footer/CFooter';
import { CHeader } from './components/Header/CHeader';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { MainState } from './MainProvider';
import { ArrowUpOutlined } from '@ant-design/icons';
import { API_URL } from './common/enum/enum';
import axios from 'axios';
import { motion } from 'framer-motion';
const LazyComponent = lazy(() => import('./components/AnimationRouter'));
const animation = {
    from: { x: 100, opacity: 0 },
    to: { x: 0, opacity: 1 }
};

function App() {

    const { typeNews, setTypeNews, footerHeight, setFooterHeight, topicContent, setTopicContent, topiclst, setTopiclst, clickedCluster, setClickedCluster, isHideHeader, setIsHideHeader, isHideFooter, setIsHideFooter, isResponsive, setIsResponsive, widthWeb, setWidthWeb } = MainState();
    const [scrolledDown, setScrolledDown] = useState(false); // Kiểm tra xem người dùng đã cuộn xuống chưa
    const [loadingHeight, setLoadingHeight] = useState(0); // Chiều cao của phần loading
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const navigate = useNavigate();
    // // Biến lấy địa chỉ url của trang
    const location = useLocation();
    // const [isShowFooter, setIsShowFooter] = useState(true);

    useEffect(() => {
        handleClickScollUpButton();

    }, []);

    // Xử lý sự kiện khi người dùng cuộn trang
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        setWidthWeb(windowSize[0]);

        window.addEventListener('resize', handleWindowResize);
        if (window.innerWidth > 450) {
            setIsResponsive(false);
        }
        if (window.innerWidth <= 450) {
            setIsResponsive(true);
        }
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });


    useEffect(() => {
        checkAndHandleExpiredToken();
    }, []);


    // Sự kiện khi người dùng cuộn trang
    useEffect(() => {
        document.body.addEventListener("scroll", handleScroll);
        return () => {
            document.body.removeEventListener("scroll", handleScroll);
        };
    }, [loadingHeight]);

    useEffect(() => {
        handleClickScollUpButton();

        let urlLink = location.pathname;
        if (urlLink.includes('login') || urlLink.includes('register') || urlLink.includes('coffee') || urlLink.includes('forgot-password')) {
            setIsHideHeader(true);
            setIsHideFooter(true);
        }
        else {
            setIsHideHeader(false);
            setIsHideFooter(false);
        }

        if (!urlLink.includes('detail-news')) {
            localStorage.removeItem('id-news');
        }
    }, [location]);

    // const getAllTopicRequest = async () => {
    //   await axios.get(API_URL.GET_TOPICS_LIST)
    //     .then((res) => {
    //       console.log(res.data.docs);
    //       setTopiclst(res.data.docs);
    //     });
    // }

    // Hàm xử lý sự kiện khi người dùng cuộn trang
    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = document.body;
        // Kiểm tra đã cuộn xuống chưa
        scrollTop > 0 ? setScrolledDown(true) : setScrolledDown(false);

    };


    // Hàm cuộn lên đầu trang
    const handleClickScollUpButton = () => {
        document.body.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    // Hàm kiểm tra token hết hạn và xử lý
    const checkAndHandleExpiredToken = (): boolean => {
        console.log(localStorage.getItem('tokenExpiration'));
        const expiration = localStorage.getItem('tokenExpiration');
        if (!expiration) return true;

        const isExpired = new Date().getTime() > parseInt(expiration, 10);

        if (isExpired) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('tokenExpiration');

            notification.warning({
                message: 'Phiên đăng nhập hết hạn',
                // description: 'Vui lòng đăng nhập lại để tiếp tục.',
                duration: 3,
            });

            // navigate('/login');
        }

        return isExpired;
    }

    return (
        <Layout>
            {!isHideHeader &&
                <CHeader />
            }
            <Suspense fallback={<div>Loading...</div>}>
                <LazyComponent />
            </Suspense>
            {!isHideFooter &&
                <CFooter />
            }
            {/* <motion.button
                className=''
                style={{
                    position: "fixed",
                    bottom: "1vw",
                    right: "1vw",
                    padding: "1vw",
                    borderRadius: "50%",
                    backgroundColor: "#333",
                    color: "#fff",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    outline: "none",
                    border: "none",
                    zIndex: 999,
                    opacity: scrolledDown ? 1 : 0, // set opacity based on whether the user has scrolled down or not
                    transition: "opacity 0.3s ease" // add a transition for a smoother effect
                }}
                onClick={handleClickScollUpButton}
                animate={scrolledDown ? "to" : "from"} // set the animation based on whether the user has scrolled down or not
                variants={animation} // pass the animation to the button
            >
                <ArrowUpOutlined />
            </motion.button> */}
            {/* {(isShowFooter) && <CFooter />} */}

        </Layout>
    );
}

export default App;
